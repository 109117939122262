import React from 'react';
import { Route, Switch } from 'react-router-dom';
import BoxNShip from '../views/box-n-ship';
import TrackNTrace from '../views/track-n-trace';
import CutSmart from '../views/cut-smart';
import Cache from '../views/cache';
import Salt from '../views/salt';
import Design from '../views/design';

const ProductsSubRouter = () => {
  return (
    <Switch>
      <Route path='/website/products/boxnship' component={BoxNShip} />
      <Route path='/website/products/tracktrace' component={TrackNTrace} />
      <Route path='/website/products/cutsmart' component={CutSmart} />
      <Route path='/website/products/cache' component={Cache} />
      <Route path='/website/products/salt' component={Salt} />
      <Route path='/website/products/design' component={Design} />
    </Switch>
  );
};

export default ProductsSubRouter;
