import React, { Fragment, useCallback, useState } from 'react';
import reachusimage from '../assets/img/reach-us-image.png';
import Footer from '../components/footer';
import { toastError, toastSuccess } from '../helpers/Helper';
import { useDispatch } from 'react-redux';
import { actions, ResponseCodes } from '@naadi/framework';
import { createLead } from '../services/web';
import WhatsAppChat from '../components/whatsup-link';
import { useEffect } from 'react';
import blackClose from '../assets/img/black-close.svg';
const ReachUs = () => {
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [company, setCompany] = useState('');
  const [message, setMessage] = useState('');
  const [referralSource, setReferralSource] = useState('');
  const [valid, setValid] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [errors, setErrors] = useState({
    name: '',
    email: '',
    mobile: '',
    referralSource: '',
  });
  const validateForm = useCallback(() => {
    let valid = true;
    const errors = {};

    if (!name || name.length === 0) {
      errors.name = 'Name is required';
      valid = false;
    } else if (name && name.length < 3) {
      errors.name = 'Name should be at least three characters';
      valid = false;
    }

    if (!email) {
      errors.email = 'Email is required';
      valid = false;
    } else if (!/\S+@\S+\.\S+$/i.test(email)) {
      errors.email = 'Email address is invalid';
      valid = false;
    }

    if (!mobile) {
      errors.mobile = 'Mobile number is required';
      valid = false;
    }

    if (mobile && mobile.length < 10) {
      errors.mobile = 'Mobile number should be 10 digits';
      valid = false;
    }

    return { valid, errors };
  }, [email, mobile, name]);

  useEffect(() => {
    const { valid } = validateForm();
    setValid(valid);
  }, [validateForm]);

  const createUserHandler = useCallback(async () => {
    const { valid, errors } = validateForm();

    if (!valid) {
      setErrors(errors);
      toastError('Enter the Details before Submitting');
      return;
    }
    const formattedString =
      selectedOptions &&
      selectedOptions.length > 0 &&
      selectedOptions.map(option => option.split(' ')[0]).join(',');

    try {
      dispatch(actions.busy.add('CREATE_LEAD'));
      const resp = await createLead({
        name: name,
        mobile_no: mobile,
        email_id: email,
        company,
        message,
        lead_source: referralSource,
        interested_in: formattedString,
      });
      if (resp.status !== ResponseCodes.SUCCESS) {
        toastError(
          'Unable to Submit the Request at this time. Please try after some time'
        );
        return;
      } else {
        toastSuccess(
          'Thank you for reaching out to us! Our Team will get in touch with you soon.'
        );
        setName('');
        setEmail('');
        setMobile('');
        setCompany('');
        setMessage('');
        setErrors('');
        setReferralSource('');
      }
    } finally {
      dispatch(actions.busy.remove('CREATE_LEAD'));
    }
  }, [
    company,
    dispatch,
    email,
    message,
    mobile,
    name,
    referralSource,
    validateForm,
    selectedOptions,
  ]);

  const ProductDropdown = () => {
    const options = ['Manufacturing Products', 'Design Products'];

    const toggleOption = option => {
      if (selectedOptions.includes(option)) {
        setSelectedOptions(selectedOptions.filter(item => item !== option));
      } else {
        setSelectedOptions([...selectedOptions, option]);
      }
    };
    console.log(selectedOptions);
    return (
      <div>
        <label className='input-label'>INTERESTED IN</label>
        <div
          style={{
            border: '1px solid #ccc',
            padding: '8px',
            borderRadius: '4px',
            cursor: 'pointer',
            position: 'relative',
          }}
        >
          {selectedOptions.map(item => (
            <span
              key={item}
              style={{
                padding: '0px 8px',
                margin: '2px',
                display: 'inline-block',
                borderRadius: '10px',
                border: '1px solid #027AFF',
                backgroundColor: '#AFD6FF',
              }}
            >
              {item}{' '}
              <span
                style={{ cursor: 'pointer', marginLeft: '4px' }}
                onClick={e => {
                  e.stopPropagation();
                  toggleOption(item);
                }}
              >
                <img src={blackClose} alt='X' />
              </span>
            </span>
          ))}
          <div
            style={{
              marginTop: '5px',
              borderTop: '1px solid #ccc',
              paddingTop: '5px',
              position: 'absolute',
              width: '100%',
              backgroundColor: '#fff',
              boxShadow: '0px 4px 6px rgba(0,0,0,0.1)',
            }}
          >
            {options.map(
              option =>
                !selectedOptions.includes(option) && (
                  <div
                    key={option}
                    style={{
                      padding: '5px',
                      cursor: 'pointer',
                      borderBottom: '1px solid #eee',
                    }}
                    onClick={() => toggleOption(option)}
                  >
                    {option}
                  </div>
                )
            )}
          </div>
        </div>
      </div>
    );
  };
  return (
    <Fragment>
      <div className='px-64 py-64'>
        <div className='row position-relative w-100' style={{ margin: '0px' }}>
          <div className='col-6 d-sm-none d-lg-block'>
            <div className='d-flex justify-content-end align-items-center'>
              <div className='flex-1'>
                <img src={reachusimage} alt={'Reach Us'} width='100%' />
              </div>
            </div>
          </div>
          <div className='col col-lg-6'>
            <div style={{ fontFamily: 'Rubik', fontSize: '28px' }}>
              Drop us a line
            </div>
            <div className='py-32'>
              <div className='input-box-border'>
                <div className='input-label'>YOUR NAME</div>
                <input
                  placeholder='Enter your name'
                  value={name}
                  onChange={e => {
                    let _name = e.target.value || '';
                    if (_name.length > 100) {
                      _name = _name.substring(0, 100);
                    }
                    setName(_name);
                  }}
                />
                {errors.name && (
                  <span className='input-label text-danger'>{errors.name}</span>
                )}
              </div>
              <div className='input-box-border'>
                <div className='input-label'>EMAIL ADDRESS</div>
                <input
                  placeholder='Enter Email ID'
                  value={email}
                  id='email'
                  name='email'
                  onChange={e => {
                    let _val = e.target.value || '';
                    if (_val.length > 100) {
                      _val = _val.substring(0, 100);
                    }
                    setEmail(_val);
                  }}
                />
                {errors.email && (
                  <span className='input-label text-danger'>
                    {errors.email}
                  </span>
                )}
              </div>

              <div className='input-box-border'>
                <div className='input-label'>MOBILE NUMBER</div>
                <input
                  placeholder='Enter your mobile number'
                  value={mobile}
                  onChange={e => {
                    let _val = e.target.value || '';
                    if (_val.length > 20) {
                      _val = _val.substring(0, 20);
                    }
                    setMobile(_val);
                  }}
                />
                {errors.mobile && (
                  <span className='input-label text-danger'>
                    {errors.mobile}
                  </span>
                )}
              </div>
              <div className='input-box-border'>
                <div className='input-label'>COMPANY</div>
                <input
                  placeholder='Enter Company name'
                  value={company}
                  onChange={e => {
                    let _val = e.target.value || '';
                    if (_val.length > 100) {
                      _val = _val.substring(0, 100);
                    }
                    setCompany(_val);
                  }}
                />
              </div>
              <div className='input-box-border'>
                <div className='input-label'>MESSAGE</div>
                <input
                  placeholder='Enter your message'
                  value={message}
                  onChange={e => {
                    let _val = e.target.value || '';
                    if (_val.length > 800) {
                      _val = _val.substring(0, 800);
                    }
                    setMessage(_val);
                  }}
                />
              </div>
              <div className='input-box-border'>
                <div className='input-label'>HOW DID YOU HEAR ABOUT US</div>
                <input
                  placeholder='Enter your referral source'
                  value={referralSource}
                  onChange={e => {
                    let _val = e.target.value || '';
                    if (_val.length > 800) {
                      _val = _val.substring(0, 800);
                    }
                    setReferralSource(_val);
                  }}
                />
              </div>
              <div className='input-box-border'>
                <ProductDropdown />
              </div>
            </div>
            <WhatsAppChat />
            <div className='d-flex justify-content-end'>
              <div
                className={`btn ${valid ? 'btn-primary' : 'btn-ghost'}`}
                style={{ cursor: valid ? 'pointer' : 'not-allowed' }}
                onClick={() => {
                  createUserHandler();
                }}
              >
                Submit
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default ReachUs;
