import React, { Fragment } from 'react';
import Footer from '../components/footer';
import useMobile from '../hooks/useMobile';
import { useHistory } from 'react-router-dom';
import WhatsAppChat from '../components/whatsup-link';

//import naadiLogo from '../assets/img/design-img1-logo.svg';
//import coohomLogo from '../assets/img/coohom-logo.svg';
const WEBKIT_CENTER = '-webkit-center';

const Aboutus = () => {
  const history = useHistory();
  //eslint-disable-next-line
  const [isTab, isMobile] = useMobile();
  return (
    <Fragment>
      <div className='design-container'>
        <div
          className='partnership-container'
          style={{ textAlign: WEBKIT_CENTER }}
        >
          <h2>Our partnership with Coohom</h2>
          <div className='d-flex logos'>
            <img
              src='https://naadi-cdn.naadi.io/website/assets/images/design-img1-logo.svg'
              alt='NAADI'
              className='logo-naadi'
            />

            <div className='logo-x'>X</div>

            <img
              src='https://naadi-cdn.naadi.io/website/assets/images/coohom-logo.svg'
              alt='COOHOM'
              className='logo-coohom'
            />
          </div>
          <div className='aboutus-text'>
            <p>
              The interior design and manufacturing industry is evolving at an
              unprecedented pace, driven by technological advancements and the
              growing demand for efficiency, precision, and scalability. At
              Naadi, we recognized the urgent need for a seamless, reliable, and
              cost-effective Design to Manufacturing solution that could cater
              to the modern industry’s demands.
              <b>Our partnership with Coohom </b>was born out of this
              vision—combining industry-leading manufacturing expertise with
              cutting-edge design technology to create a comprehensive,
              end-to-end solution that empowers businesses.
            </p>
            <p>
              Together, Naadi and Coohom are working to make these advanced
              tools more accessible, helping businesses transition from a
              people-dependent model to a technology-driven ecosystem. We
              believe that advanced design and manufacturing solutions should
              not be limited to large enterprises. Our collaboration is focused
              on making these technologies available to small and medium
              businesses, independent designers, and large-scale manufacturers
              alike.
            </p>
            <p>
              Together, Naadi and Coohom are working to make these advanced
              tools more accessible, helping businesses transition from a
              people-dependent model to a technology-driven ecosystem. We
              believe that advanced design and manufacturing solutions should
              not be limited to large enterprises. Our collaboration is focused
              on making these technologies available to small and medium
              businesses, independent designers, and large-scale manufacturers
              alike.
            </p>
          </div>
        </div>
        <div className='py-64'>
          <div className='text-center'>
            <div
              className='btn btn-outline-primary cursor-pointer'
              style={{ minWidth: isMobile || isTab ? '150px' : '400px' }}
              onClick={() => {
                history.push('/website/reachus');
              }}
            >
              Book a Demo
            </div>
          </div>
          <WhatsAppChat />
          <div className='text-center ' style={{ paddingTop: '32px' }}>
            <div
              className='btn btn-ghost cursor-pointer'
              style={{ minWidth: '400px' }}
              onClick={() => {
                history.push('/website');
              }}
            >
              Home
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default Aboutus;
