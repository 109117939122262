import React, { Fragment } from 'react';
import saltimage from '../assets/img/salt-image.png';
import Footer from '../components/footer';
import useMobile from '../hooks/useMobile';
import { ModulesComponent } from '../components/mainpage/section-1';
import { useHistory } from 'react-router-dom';
import { ProductStepIcon, ShowModuleContent, ShowModule } from './box-n-ship';
import WhatsAppChat from '../components/whatsup-link';

//const TEXT_RIGHT = 'text-right';
const JUSTIFY_CONTENT_END = 'justify-content-end';
const JUSTIFY_CONTENT_CENTER = 'justify-content-center';

export const SaltImage = ({ alignment, isMobile }) => {
  return (
    <div
      className={`d-flex ${
        isMobile
          ? 'justify-content-center'
          : alignment === 'right'
          ? JUSTIFY_CONTENT_END
          : alignment === 'left'
          ? ''
          : JUSTIFY_CONTENT_CENTER
      }`}
      style={{ maxHeight: '100%' }}
    >
      <img
        style={{
          maxHeight: '100%',
          maxWidth: '100%',
          height: '400px',
          objectFit: 'contain',
          objectPosition: alignment,
        }}
        alt='Salt'
        src={saltimage}
      />
    </div>
  );
};

const titleStyle = {
  paddingBottom: '16px',
  fontSize: '36px',
  fontColor: '#006EED',
  lineHeight: '42px',
};
const OrderManagementIcon = () => {
  return (
    <svg
      width='32'
      height='43'
      viewBox='0 0 32 43'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M27.8125 5.5H23.3828C22.3164 2.46484 19.4453 0.25 16 0.25C12.5547 0.25 9.68359 2.46484 8.61719 5.5H4.1875C2.05469 5.5 0.25 7.22266 0.25 9.4375V38.3125C0.25 40.5273 2.05469 42.25 4.1875 42.25H27.8125C29.9453 42.25 31.75 40.5273 31.75 38.3125V9.4375C31.75 7.22266 29.9453 5.5 27.8125 5.5ZM16 5.5C17.4766 5.5 18.625 6.64844 18.625 8.125C18.625 9.60156 17.4766 10.75 16 10.75C14.5234 10.75 13.375 9.60156 13.375 8.125C13.375 6.64844 14.5234 5.5 16 5.5ZM8.125 32.4062C7.05859 32.4062 6.15625 31.5039 6.15625 30.4375C6.15625 29.3711 7.05859 28.4688 8.125 28.4688C9.19141 28.4688 10.0938 29.3711 10.0938 30.4375C10.0938 31.5039 9.19141 32.4062 8.125 32.4062ZM9.4375 23.875C9.10938 23.875 8.78125 23.7109 8.53516 23.4648L5.91016 20.8398C5.33594 20.3477 5.33594 19.5273 5.91016 19.0352C6.48438 18.543 7.22266 18.4609 7.71484 19.0352L9.4375 20.6758L13.7852 16.4102C14.2773 15.8359 15.0977 15.8359 15.5898 16.4102C16.082 16.9844 16.1641 17.7227 15.5898 18.2148L10.3398 23.4648C10.0938 23.7109 9.76562 23.875 9.4375 23.875ZM25.1875 31.75H14.6875C13.9492 31.75 13.375 31.1758 13.375 30.4375C13.375 29.6992 13.9492 29.125 14.6875 29.125H25.1875C25.9258 29.125 26.5 29.6992 26.5 30.4375C26.5 31.1758 25.9258 31.75 25.1875 31.75ZM25.1875 23.875H17.3125C16.5742 23.875 16 23.3008 16 22.5625C16 21.8242 16.5742 21.25 17.3125 21.25H25.1875C25.9258 21.25 26.5 21.8242 26.5 22.5625C26.5 23.3008 25.9258 23.875 25.1875 23.875Z'
        fill='#027AFF'
      />
    </svg>
  );
};
const OrderManagement = () => {
  //eslint-disable-next-line
  const [isTab, isMobile] = useMobile();
  return (
    <div className='section-2-content section-2-sub-content'>
      <div className='pb-4'>
        <ShowModule
          isMobile={isMobile}
          isLeft={false}
          image={
            <ProductStepIcon
              alignment={'left'}
              isMobile={isMobile}
              isLeft={false}
              icon={<OrderManagementIcon />}
            />
          }
          content={
            <ShowModuleContent
              isMobile={isMobile}
              isLeft={false}
              title={<div style={titleStyle}>Order Management</div>}
              content='Create and manage all your orders'
            />
          }
        />
      </div>
    </div>
  );
};
const DesignToolIntegrationIcon = () => {
  return (
    <svg
      width='49'
      height='44'
      viewBox='0 0 49 44'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9.16797 2.64453L19.9141 13.3906L13.4336 19.8711L2.6875 9.125C0.882812 7.32031 0.882812 4.44922 2.6875 2.64453C4.49219 0.839844 7.36328 0.839844 9.16797 2.64453ZM34.1875 43.25C29.7578 43.25 26.0664 40.2148 25.1641 36.0312L36.0742 25.1211C40.2578 26.0234 43.375 29.7148 43.375 34.0625C43.375 34.3906 43.293 34.7188 43.293 35.0469C43.1289 36.5234 44.1133 38 45.5898 38H46C47.3945 38 48.625 39.2305 48.625 40.625C48.625 42.1016 47.3945 43.25 46 43.25H34.1875ZM44.3594 4.77734C46.4102 6.82812 46.4102 10.1914 44.3594 12.2422L39.7656 16.8359L30.4141 7.48438L35.0078 2.89062C37.0586 0.839844 40.4219 0.839844 42.4727 2.89062L44.3594 4.77734ZM7.60938 30.2891L28.5273 9.37109L37.8789 18.7227L16.9609 39.6406C16.4688 40.1328 15.7305 40.543 14.9922 40.7891L6.46094 43.25C5.80469 43.4141 5.06641 43.25 4.57422 42.6758C4 42.1836 3.83594 41.4453 4 40.7891L6.46094 32.2578C6.70703 31.5195 7.11719 30.7812 7.60938 30.2891Z'
        fill='#027AFF'
      />
    </svg>
  );
};
const DesignToolIntegration = () => {
  //eslint-disable-next-line
  const [isTab, isMobile] = useMobile();
  return (
    <div className='section-2-content section-2-sub-content'>
      <div className='pb-4'>
        <ShowModule
          isMobile={isMobile}
          isLeft={true}
          image={
            <ProductStepIcon
              alignment={'right'}
              isMobile={isMobile}
              isLeft={true}
              icon={<DesignToolIntegrationIcon />}
            />
          }
          content={
            <ShowModuleContent
              isMobile={isMobile}
              isLeft={true}
              title={<div style={titleStyle}>Design Tool Integration</div>}
              content='Naadi Bot can fetch all your production related data like cutlist, CNC Programs and Images from your design tool'
            />
          }
        />
      </div>
    </div>
  );
};

const ReportsIcon = () => {
  return (
    <svg
      width='53'
      height='43'
      viewBox='0 0 53 43'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M27.2383 13.9492C26.5 13.2109 25.1875 13.2109 24.4492 13.9492L23.2188 15.1797C23.6289 16.2461 23.875 17.3945 23.875 18.625C23.875 19.1992 23.793 19.6914 23.7109 20.2656L25.8438 18.1328L30.3555 22.6445C31.0938 23.3828 32.4062 23.3828 33.1445 22.6445L39.0508 16.7383L41.0195 18.707C42.0039 19.6914 43.5625 19.0352 43.5625 17.7227V10.4219C43.5625 9.84766 43.1523 9.4375 42.5781 9.4375H35.2773C33.9648 9.4375 33.3086 10.9961 34.293 11.9805L36.2617 13.9492L31.75 18.4609L27.2383 13.9492ZM13.375 26.5C17.7227 26.5 21.25 22.9727 21.25 18.625C21.25 14.2773 17.7227 10.75 13.375 10.75C9.02734 10.75 5.5 14.2773 5.5 18.625C5.5 22.9727 9.02734 26.5 13.375 26.5ZM48.8125 0.25H17.3125C15.1797 0.25 13.375 2.05469 13.375 4.35156V8.125C15.2617 8.125 17.0664 8.69922 18.625 9.60156V5.5H47.5V29.125H23.793C25.5977 30.4375 27.0742 32.2422 27.9766 34.375H48.8125C50.9453 34.375 52.75 32.5703 52.75 30.2734V4.35156C52.75 2.05469 50.9453 0.25 48.8125 0.25ZM16 29.125H10.75C4.92578 29.125 0.25 33.8008 0.25 39.625C0.25 41.1016 1.39844 42.25 2.875 42.25H23.875C25.3516 42.25 26.5 41.1016 26.5 39.625C26.5 33.8008 21.8242 29.125 16 29.125Z'
        fill='#027AFF'
      />
    </svg>
  );
};
const Reports = () => {
  //eslint-disable-next-line
  const [isTab, isMobile] = useMobile();
  return (
    <div className='section-2-content section-2-sub-content'>
      <div className='pb-4'>
        <ShowModule
          isMobile={isMobile}
          isLeft={false}
          image={
            <ProductStepIcon
              alignment={'left'}
              isMobile={isMobile}
              isLeft={false}
              icon={<ReportsIcon />}
            />
          }
          content={
            <ShowModuleContent
              isMobile={isMobile}
              isLeft={false}
              title={<div style={titleStyle}>Reports</div>}
              content={
                'Real time and historic data for analysis and decision making.'
              }
            />
          }
        />
      </div>
    </div>
  );
};
const Dashboards = () => {
  //eslint-disable-next-line
  const [isTab, isMobile] = useMobile();
  return (
    <div className='section-2-content section-2-sub-content'>
      <div className='pb-4'>
        <ShowModule
          isMobile={isMobile}
          isLeft={true}
          image={
            <ProductStepIcon
              alignment={'right'}
              isMobile={isMobile}
              isLeft={true}
              icon={<ReportsIcon />}
            />
          }
          content={
            <ShowModuleContent
              isMobile={isMobile}
              isLeft={true}
              title={<div style={titleStyle}>Dashboards</div>}
              content='Visual representation of your production KPI’s at a glance. '
            />
          }
        />
      </div>
    </div>
  );
};
const Salt = () => {
  const history = useHistory();
  //eslint-disable-next-line
  const [isTab, isMobile] = useMobile();
  return (
    <Fragment>
      <div className='d-none d-lg-block py-32'></div>
      <div className='px-32'>
        <div className='section-2-wrp' style={{ padding: '0px' }}>
          <div className='section-2-content' style={{ paddingTop: '0px' }}>
            <div className='pb-4'>
              <ShowModule
                isMobile={isMobile}
                isLeft={true}
                valign={'center'}
                image={
                  <SaltImage
                    alignment={'right'}
                    isMobile={isMobile}
                    isLeft={true}
                  />
                }
                content={
                  <ShowModuleContent
                    isMobile={isMobile}
                    isLeft={true}
                    title={<ModulesComponent name={'Salt'} />}
                    content='Orders, Reports and Dashboards'
                  />
                }
              />
            </div>
          </div>
          <OrderManagement />
          <DesignToolIntegration />
          <Reports />
          <Dashboards />
        </div>
        <div className='py-64'>
          <div className='text-center'>
            <div
              className='btn btn-outline-primary cursor-pointer'
              style={{ minWidth: isMobile || isTab ? '150px' : '400px' }}
              onClick={() => {
                history.push('/website/reachus');
              }}
            >
              Book a Demo
            </div>
          </div>
          <WhatsAppChat />
          <div className='text-center ' style={{ paddingTop: '32px' }}>
            <div
              className='btn btn-ghost cursor-pointer'
              style={{ minWidth: '400px' }}
              onClick={() => {
                history.push('/website');
              }}
            >
              Home
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default Salt;
