import React, { Fragment, useState } from 'react';
import Footer from '../components/footer';
import useMobile from '../hooks/useMobile';
import { useHistory } from 'react-router-dom';
import WhatsAppChat from '../components/whatsup-link';
//import DesignVideo from '../assets/videos/design.mp4';
//import naadiLogo from '../assets/img/design-img1-logo.svg';
//import toolCard from '../assets/img/design-img2.svg';
//import cabinetCat from '../assets/img/design-img3.svg';
//import materialLib from '../assets/img/design-img4.svg';
//import highQualityImg from '../assets/img/design-img5.svg';
//import aiAssistance from '../assets/img/design-img6.svg';
//import autoConversion from '../assets/img/design-img7.svg';
//import homeDesigned from '../assets/img/design-img8.svg';
//import designAngleLeft from '../assets/img/design-left-arrow.svg';
//import designAngleRight from '../assets/img/design-right-arrow.svg';
const WEBKIT_CENTER = '-webkit-center';

const DesignBanner = () => {
  return (
    <div className='design-banner'>
      <span className='design-banner-text'>Reinvent design with</span>
      <img
        src='https://naadi-cdn.naadi.io/website/assets/images/design-img1-logo.svg'
        alt='NAADI'
        className='design-banner-logo'
      />
    </div>
  );
};

const DesignToolCard = () => {
  return (
    <div className='design-tool-card'>
      {/* Image on the left */}
      <img
        src='https://naadi-cdn.naadi.io/website/assets/images/design-img2.svg'
        alt='Design Tools'
        className='design-tool-image'
      />

      {/* Text on the right */}
      <p className='design-tool-text'>
        <span className='design-highlight'>Ready to Use</span>{' '}
        <span className='design-normal'>Design Tool</span>
      </p>
    </div>
  );
};

const DesignToolCardMob = () => {
  return (
    <div className='design-tool-card'>
      <div style={{ width: '100%', textAlign: 'center' }}>
        <p className='design-tool-text-mob'>
          <span className='design-highlight'>Ready to Use</span>{' '}
          <span className='design-normal'>Design Tool</span>
        </p>
      </div>
    </div>
  );
};

const DesignToolCardGen = isMobile => {
  return isMobile.isMobile ? (
    <div style={{ width: '100%', textAlign: WEBKIT_CENTER }}>
      <DesignToolCardMob />
      <img
        src='https://naadi-cdn.naadi.io/website/assets/images/design-img2.svg'
        alt='Design Tools'
        className='design-tool-image'
      />
    </div>
  ) : (
    <DesignToolCard />
  );
};
const CabinetCatalogTxt = () => {
  return (
    <div className='cabinet-catalog design-header-text'>
      <div style={{ width: '100%', textAlign: 'center' }}>
        <h2>
          <span className='bold'>Pre-built</span>{' '}
          <span className='design-highlight'>Cabinet Catalog</span>
        </h2>
        <p>
          Quickly choose from an extensive collection of pre-designed cabinets
          and layouts for your projects.
        </p>
      </div>
    </div>
  );
};
const CabinetCatalog = isMobile => {
  return isMobile.isMobile ? (
    <div style={{ width: '100%', textAlign: WEBKIT_CENTER }}>
      <div style={{ width: '100%', textAlign: WEBKIT_CENTER }}>
        <CabinetCatalogTxt />
        <img
          src='https://naadi-cdn.naadi.io/website/assets/images/design-img3.svg'
          alt='Cabinet Catalog'
          className='design-tool-image'
          style={{ paddingTop: '20px' }}
        />
      </div>
    </div>
  ) : (
    <div>
      <div
        className='d-flex'
        style={{ paddingTop: '305px', justifyContent: 'center' }}
      >
        <CabinetCatalogTxt />
        <img
          src='https://naadi-cdn.naadi.io/website/assets/images/design-img3.svg'
          alt='Cabinet Catalog'
          className='design-tool-image'
          style={{ paddingTop: '20px' }}
        />
      </div>
    </div>
  );
};
const MaterialLibrary = isMobile => {
  const MatLibText = () => {
    return (
      <div className='cabinet-catalog design-header-text'>
        <div
          style={{
            width: '100%',
            textAlign: isMobile.isMobile ? 'center' : 'right',
          }}
        >
          <h2 style={{ textAlign: isMobile.isMobile ? 'center' : 'right' }}>
            <span className='design-highlight'>Material and Finish</span>{' '}
            <span className='bold'>Library</span>
          </h2>
          <p style={{ textAlign: isMobile.isMobile ? 'center' : 'right' }}>
            Seamlessly apply a diverse range of materials, finishes and
            appliances to your Designs.
          </p>
        </div>
      </div>
    );
  };
  return isMobile.isMobile ? (
    <div style={{ width: '100%', textAlign: 'center' }}>
      <MatLibText />
      <img
        src='https://naadi-cdn.naadi.io/website/assets/images/design-img4.svg'
        alt='Cabinet Catalog'
        className='design-tool-image'
        style={{ paddingTop: '20px' }}
      />
    </div>
  ) : (
    <div
      className='d-flex'
      style={{ paddingTop: '305px', justifyContent: 'center' }}
    >
      <img
        src='https://naadi-cdn.naadi.io/website/assets/images/design-img4.svg'
        alt='Cabinet Catalog'
        className='design-tool-image'
        style={{ paddingTop: '20px' }}
      />
      <MatLibText />
    </div>
  );
};
const HighQualityTxt = () => {
  return (
    <div className='cabinet-catalog design-header-text'>
      <div style={{ width: '100%', textAlign: 'center' }}>
        <h2>
          <span className='design-highlight'>High Quality Rendering</span>{' '}
          <span className='bold'>4K</span>
        </h2>
        <p>
          Create stunning, high-resolution renderings in minutes, perfect for
          presentations and client approval.
        </p>
      </div>
    </div>
  );
};
const HighQuality = isMobile => {
  return isMobile.isMobile ? (
    <div style={{ width: '100%', textAlign: WEBKIT_CENTER }}>
      <div style={{ width: '100%', textAlign: WEBKIT_CENTER }}>
        <HighQualityTxt />
        <img
          src='https://naadi-cdn.naadi.io/website/assets/images/design-img5.svg'
          alt='Cabinet Catalog'
          className='design-tool-image'
          style={{ paddingTop: '20px' }}
        />
      </div>
    </div>
  ) : (
    <div>
      <div
        className='d-flex'
        style={{ paddingTop: '305px', justifyContent: 'center' }}
      >
        <HighQualityTxt />
        <img
          src='https://naadi-cdn.naadi.io/website/assets/images/design-img3.svg'
          alt='Cabinet Catalog'
          className='design-tool-image'
          style={{ paddingTop: '20px' }}
        />
      </div>
    </div>
  );
};
const AIAssisted = isMobile => {
  const AIAssistedText = () => {
    return (
      <div className='cabinet-catalog design-header-text'>
        <div
          style={{
            width: '100%',
            textAlign: isMobile.isMobile ? 'center' : 'right',
          }}
        >
          <h2 style={{ textAlign: isMobile.isMobile ? 'center' : 'right' }}>
            <span className='design-highlight'>AI-Assisted</span>{' '}
            <span className='bold'>Designing</span>
          </h2>
          <p style={{ textAlign: isMobile.isMobile ? 'center' : 'right' }}>
            Receive tailored AI-powered design suggestions that save you time
            and help you make smarter choices.
          </p>
        </div>
      </div>
    );
  };
  return isMobile.isMobile ? (
    <div style={{ width: '100%', textAlign: 'center' }}>
      <AIAssistedText />
      <img
        src='https://naadi-cdn.naadi.io/website/assets/images/design-img6.svg'
        alt='Cabinet Catalog'
        className='design-tool-image'
        style={{ paddingTop: '20px' }}
      />
    </div>
  ) : (
    <div
      className='d-flex'
      style={{ paddingTop: '305px', justifyContent: 'center' }}
    >
      <img
        src='https://naadi-cdn.naadi.io/website/assets/images/design-img6.svg'
        alt='Cabinet Catalog'
        className='design-tool-image'
        style={{ paddingTop: '20px' }}
      />
      <AIAssistedText />
    </div>
  );
};
const AutoConversionTxt = isMobile => {
  return (
    <div className='cabinet-catalog design-header-text'>
      <div
        style={{
          width: '100%',
          textAlign: 'center',
          paddingRight: isMobile.isMobile ? '0px' : '50px',
        }}
      >
        <h2>
          <span className='design-highlight'>Auto Conversion of 3D</span>{' '}
          <span className='bold'>to 2D</span>
        </h2>
        <p>
          Instantly convert 3D designs into accurate 2D technical drawings with
          just one click.
        </p>
      </div>
    </div>
  );
};
const AutoConversion = isMobile => {
  return isMobile.isMobile ? (
    <div style={{ width: '100%', textAlign: WEBKIT_CENTER }}>
      <div style={{ width: '100%', textAlign: WEBKIT_CENTER }}>
        <AutoConversionTxt isMobile={isMobile} />
        <img
          src='https://naadi-cdn.naadi.io/website/assets/images/design-img7.svg'
          alt='Cabinet Catalog'
          className='design-tool-image'
          style={{ paddingTop: '20px' }}
        />
      </div>
    </div>
  ) : (
    <div>
      <div
        className='d-flex'
        style={{ paddingTop: '305px', justifyContent: 'center' }}
      >
        <AutoConversionTxt isMobile={isMobile} />
        <img
          src='https://naadi-cdn.naadi.io/website/assets/images/design-img7.svg'
          alt='Cabinet Catalog'
          className='design-tool-image'
          style={{ paddingTop: '20px' }}
        />
      </div>
    </div>
  );
};
const ImageCarousel = isMobile => {
  const images = [
    'https://naadi-cdn.naadi.io/website/assets/images/design-img8.svg',
    'https://naadi-cdn.naadi.io/website/assets/images/Website-1.png',
    'https://naadi-cdn.naadi.io/website/assets/images/Website-2.png',
    'https://naadi-cdn.naadi.io/website/assets/images/Website-3.png',
    'https://naadi-cdn.naadi.io/website/assets/images/website-4%20.png',
    'https://naadi-cdn.naadi.io/website/assets/images/Website-5.png',
    'https://naadi-cdn.naadi.io/website/assets/images/Website-6.png',
    'https://naadi-cdn.naadi.io/website/assets/images/Website-7.png',
    'https://naadi-cdn.naadi.io/website/assets/images/Website-8.png',
    'https://naadi-cdn.naadi.io/website/assets/images/Website-9.png',
    'https://naadi-cdn.naadi.io/website/assets/images/Website-10.png',
    'https://naadi-cdn.naadi.io/website/assets/images/Website-11.png',
    'https://naadi-cdn.naadi.io/website/assets/images/Website-12.png',
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const prevImage = () => {
    setCurrentIndex(prevIndex =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const nextImage = () => {
    setCurrentIndex(prevIndex =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <div className='flex items-center justify-center h-screen bg-gray-100'>
      <div className='image-container relative w-3/4 md:w-1/2 lg:w-1/3'>
        {/* Left Arrow */}
        <button onClick={prevImage} className='design-left-arrow-box'>
          <img
            src='https://naadi-cdn.naadi.io/website/assets/images/design-left-arrow.svg'
            alt='Prev'
            style={{
              height: isMobile.isMobile ? '10px' : '20px',
              width: isMobile.isMobile ? '10px' : '20px',
            }}
          />
        </button>

        {/* Ensure Safe Index */}
        {images.length > 0 &&
        Number.isInteger(currentIndex) &&
        currentIndex >= 0 &&
        currentIndex < images.length ? (
          <img
            src={images[`${currentIndex}`]}
            alt={`Gallery ${currentIndex + 1}`}
            //className='w-full h-auto rounded-lg shadow-lg'
            className='design-tool-image'
            style={{ paddingTop: '20px', width: 'auto' }}
          />
        ) : (
          <p className='text-center text-red-500'>Error loading image</p>
        )}

        {/* Right Arrow */}
        <button onClick={nextImage} className='design-right-arrow-box'>
          <img
            src='https://naadi-cdn.naadi.io/website/assets/images/design-right-arrow.svg'
            alt='Next'
            style={{
              height: isMobile.isMobile ? '10px' : '20px',
              width: isMobile.isMobile ? '10px' : '20px',
            }}
          />
        </button>
      </div>
    </div>
  );
};
const Design = () => {
  const history = useHistory();
  //eslint-disable-next-line
  const [isTab, isMobile] = useMobile();
  return (
    <Fragment>
      <div className='d-none d-lg-block py-32'></div>

      <div className='section-2-wrp' style={{ padding: '0px' }}>
        <div className='section-2-content' style={{ paddingTop: '0px' }}>
          <div className='pb-4'>
            <video
              autoPlay
              muted
              loop
              style={{
                width: '100%',
                height: 'auto',
                display: 'block',
              }}
            >
              <source
                src='https://naadi-cdn.naadi.io/website/assets/images/design.mp4'
                type='video/mp4'
              />
              Your browser does not support the video tag.
            </video>
            <DesignBanner />
          </div>
        </div>
        <DesignToolCardGen isMobile={isMobile || isTab} />
        <CabinetCatalog isMobile={isMobile || isTab} />
        <MaterialLibrary isMobile={isMobile || isTab} />
        <HighQuality isMobile={isMobile || isTab} />
        <AIAssisted isMobile={isMobile || isTab} />
        <AutoConversion isMobile={isMobile || isTab} />
        <div style={{ width: '100%', textAlign: WEBKIT_CENTER }}>
          <div className='home-designed'>Homes designed using Naadi...</div>
          <div
            className='design-tool-image'
            style={{
              paddingTop: '20px',
              width: 'auto',
              marginBottom: isMobile || isTab ? '80px' : '109px',
            }}
          >
            <ImageCarousel isMobile={isMobile || isTab} />
          </div>
        </div>
      </div>
      <div className='py-64'>
        <div className='text-center'>
          <div
            className='btn btn-outline-primary cursor-pointer'
            style={{ minWidth: isMobile || isTab ? '150px' : '400px' }}
            onClick={() => {
              history.push('/website/reachus');
            }}
          >
            Book a Demo
          </div>
        </div>
        <WhatsAppChat />
        <div className='text-center ' style={{ paddingTop: '32px' }}>
          <div
            className='btn btn-ghost cursor-pointer'
            style={{ minWidth: '400px' }}
            onClick={() => {
              history.push('/website');
            }}
          >
            Home
          </div>
        </div>
      </div>

      <Footer />
    </Fragment>
  );
};

export default Design;
