import React, { Fragment } from 'react';
import tracktraceimage from '../assets/img/tracktrace-image.png';
import Footer from '../components/footer';
import useMobile from '../hooks/useMobile';
import { ModulesComponent } from '../components/mainpage/section-1';
import { useHistory } from 'react-router-dom';
import { ProductStepIcon, ShowModuleContent, ShowModule } from './box-n-ship';
import WhatsAppChat from '../components/whatsup-link';

//const TEXT_RIGHT = 'text-right';
const JUSTIFY_CONTENT_END = 'justify-content-end';
const JUSTIFY_CONTENT_CENTER = 'justify-content-center';

export const TrackNTraceImage = ({ alignment, isMobile }) => {
  return (
    <div
      className={`d-flex ${
        isMobile
          ? 'justify-content-center'
          : alignment === 'right'
          ? JUSTIFY_CONTENT_END
          : alignment === 'left'
          ? ''
          : JUSTIFY_CONTENT_CENTER
      }`}
      style={{ maxHeight: '100%' }}
    >
      <img
        style={{
          maxHeight: '100%',
          maxWidth: '100%',
          height: '400px',
          objectFit: 'contain',
          objectPosition: alignment,
        }}
        alt='Track N Trace'
        src={tracktraceimage}
      />
    </div>
  );
};

const titleStyle = {
  paddingBottom: '16px',
  fontSize: '36px',
  fontColor: '#006EED',
  lineHeight: '42px',
};
const TrackMeasureIcon = () => {
  return (
    <svg
      width='44'
      height='38'
      viewBox='0 0 44 38'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M28.8086 16.0508C27.9062 16.8711 26.5938 16.8711 25.6094 16.1328L16.75 9.48828L5.18359 18.6758C4.11719 19.5781 2.47656 19.4141 1.57422 18.2656C0.589844 17.1992 0.835938 15.5586 1.98438 14.5742L15.1094 4.15625C16.0117 3.33594 17.3242 3.33594 18.3086 4.07422L27.168 10.7188L38.7344 1.53125C39.8008 0.546875 41.4414 0.792969 42.3438 1.94141C43.3281 3.00781 43.082 4.64844 41.9336 5.55078L28.8086 16.0508ZM14.125 19.25C14.125 17.8555 15.2734 16.625 16.75 16.625C18.1445 16.625 19.375 17.8555 19.375 19.25V35C19.375 36.4766 18.1445 37.625 16.75 37.625C15.2734 37.625 14.125 36.4766 14.125 35V19.25ZM3.625 27.125C3.625 25.7305 4.77344 24.5 6.25 24.5C7.64453 24.5 8.875 25.7305 8.875 27.125V35C8.875 36.4766 7.64453 37.625 6.25 37.625C4.77344 37.625 3.625 36.4766 3.625 35V27.125ZM29.875 24.5V35C29.875 36.4766 28.6445 37.625 27.25 37.625C25.7734 37.625 24.625 36.4766 24.625 35V24.5C24.625 23.1055 25.7734 21.875 27.25 21.875C28.6445 21.875 29.875 23.1055 29.875 24.5ZM35.125 19.25C35.125 17.8555 36.2734 16.625 37.75 16.625C39.1445 16.625 40.375 17.8555 40.375 19.25V35C40.375 36.4766 39.1445 37.625 37.75 37.625C36.2734 37.625 35.125 36.4766 35.125 35V19.25Z'
        fill='#027AFF'
      />
    </svg>
  );
};
const TrackMeasureRecord = () => {
  //eslint-disable-next-line
  const [isTab, isMobile] = useMobile();
  return (
    <div className='section-2-content section-2-sub-content'>
      <div className='pb-4'>
        <ShowModule
          isMobile={isMobile}
          isLeft={false}
          image={
            <ProductStepIcon
              alignment={'left'}
              isMobile={isMobile}
              isLeft={false}
              icon={<TrackMeasureIcon />}
            />
          }
          content={
            <ShowModuleContent
              isMobile={isMobile}
              isLeft={false}
              title={
                <div style={titleStyle}>Digital Assistance to Packing</div>
              }
              content='Track and analyze the real time performance of man and machine in your factory '
            />
          }
        />
      </div>
    </div>
  );
};
const PaperlessProductionIcon = () => {
  return (
    <svg
      width='37'
      height='38'
      viewBox='0 0 37 38'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M13.25 0.875H2.75C1.27344 0.875 0.125 2.02344 0.125 3.5V14C0.125 15.4766 1.27344 16.625 2.75 16.625H13.25C14.7266 16.625 15.875 15.4766 15.875 14V3.5C15.875 2.02344 14.7266 0.875 13.25 0.875ZM10.625 11.375H5.375V6.125H10.625V11.375ZM13.25 21.875H2.75C1.27344 21.875 0.125 23.0234 0.125 24.5V35C0.125 36.4766 1.27344 37.625 2.75 37.625H13.25C14.7266 37.625 15.875 36.4766 15.875 35V24.5C15.875 23.0234 14.7266 21.875 13.25 21.875ZM10.625 32.375H5.375V27.125H10.625V32.375ZM34.25 0.875H23.75C22.2734 0.875 21.125 2.02344 21.125 3.5V14C21.125 15.4766 22.2734 16.625 23.75 16.625H34.25C35.7266 16.625 36.875 15.4766 36.875 14V3.5C36.875 2.02344 35.7266 0.875 34.25 0.875ZM31.625 11.375H26.375V6.125H31.625V11.375ZM29 37.625H31.625V35H29V37.625ZM34.25 24.5H29V21.875H21.125V37.625H26.375V29.75H29V32.375H36.875V21.875H34.25V24.5ZM34.25 37.625H36.875V35H34.25V37.625Z'
        fill='#027AFF'
      />
    </svg>
  );
};
const PaperlessProductionTracking = () => {
  //eslint-disable-next-line
  const [isTab, isMobile] = useMobile();
  return (
    <div className='section-2-content section-2-sub-content'>
      <div className='pb-4'>
        <ShowModule
          isMobile={isMobile}
          isLeft={true}
          image={
            <ProductStepIcon
              alignment={'right'}
              isMobile={isMobile}
              isLeft={true}
              icon={<PaperlessProductionIcon />}
            />
          }
          content={
            <ShowModuleContent
              isMobile={isMobile}
              isLeft={true}
              title={
                <div style={titleStyle}>Paperless Production Tracking</div>
              }
              content='Record information digitally. Go paperless.'
            />
          }
        />
      </div>
    </div>
  );
};
const OperatorGuidanceIcon = () => {
  return (
    <svg
      width='55'
      height='44'
      viewBox='0 0 55 44'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M41.2812 17H28.8125V21.2656C28.8125 24.3828 26.5977 27.1719 23.5625 27.5C19.9531 27.8281 17 25.0391 17 21.5938V11.5859L15.0312 12.8164C11.3398 15.0312 9.125 18.9688 9.125 23.1523L2.5625 27.0078C1.33203 27.6641 0.839844 29.3047 1.57812 30.5352L8.14062 41.9375C8.87891 43.168 10.5195 43.5781 11.75 42.9219L20.1992 38H31.4375C34.3086 38 36.6875 35.6211 36.6875 32.75C38.9023 32.75 40.625 30.9453 40.625 28.8125V24.875H41.2812C42.3477 24.875 43.25 23.9727 43.25 22.9062V18.9688C43.25 17.9023 42.3477 17 41.2812 17ZM53.4219 13.9648L46.8594 2.5625C46.1211 1.33203 44.4805 0.839844 43.25 1.57812L34.8008 6.5H29.6328C26.5156 6.5 23.4805 7.40234 20.8555 9.04297C20.1172 9.45312 19.625 10.3555 19.625 11.2578V21.5938C19.625 23.3984 21.1016 24.875 22.9062 24.875C24.7109 24.875 26.1875 23.3984 26.1875 21.5938V14.375H41.2812C43.8242 14.375 45.875 16.4258 45.875 18.9688V21.3477L52.4375 17.4922C53.668 16.7539 54.1602 15.1953 53.4219 13.9648Z'
        fill='#027AFF'
      />
    </svg>
  );
};
const OperatorGuidance = () => {
  //eslint-disable-next-line
  const [isTab, isMobile] = useMobile();
  return (
    <div className='section-2-content section-2-sub-content'>
      <div className='pb-4'>
        <ShowModule
          isMobile={isMobile}
          isLeft={false}
          image={
            <ProductStepIcon
              alignment={'left'}
              isMobile={isMobile}
              isLeft={false}
              icon={<OperatorGuidanceIcon />}
            />
          }
          content={
            <ShowModuleContent
              isMobile={isMobile}
              isLeft={false}
              title={
                <div style={titleStyle}>
                  Operator Guidance and Accountability
                </div>
              }
              content='Informs the operators on their current pipeline and guides them in their operation'
            />
          }
        />
      </div>
    </div>
  );
};
const CustomizeWorkflowIcon = () => {
  return (
    <svg
      width='53'
      height='43'
      viewBox='0 0 53 43'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M30.4375 26.5H22.5625C20.4297 26.5 18.625 28.3047 18.625 30.4375V38.3125C18.625 40.4453 20.4297 42.25 22.5625 42.25H30.4375C32.5703 42.25 34.375 40.4453 34.375 38.3125V30.4375C34.375 28.3047 32.5703 26.5 30.4375 26.5ZM48.8125 0.25H40.9375C38.8047 0.25 37 2.05469 37 4.1875V12.0625C37 14.1953 38.8047 16 40.9375 16H48.8125C50.9453 16 52.75 14.1953 52.75 12.0625V4.1875C52.75 2.05469 50.9453 0.25 48.8125 0.25ZM16 4.1875C16 2.05469 14.1953 0.25 12.0625 0.25H4.1875C2.05469 0.25 0.25 2.05469 0.25 4.1875V12.0625C0.25 14.1953 2.05469 16 4.1875 16H10.75L16.7383 26.5C17.6406 24.9414 19.2812 23.875 21.25 23.875L16 14.6055V10.75H34.375V5.5H16V4.1875Z'
        fill='#027AFF'
      />
    </svg>
  );
};
const CustomizeWorkflow = () => {
  //eslint-disable-next-line
  const [isTab, isMobile] = useMobile();
  return (
    <div className='section-2-content section-2-sub-content'>
      <div className='pb-4'>
        <ShowModule
          isMobile={isMobile}
          isLeft={true}
          image={
            <ProductStepIcon
              alignment={'right'}
              isMobile={isMobile}
              isLeft={true}
              icon={<CustomizeWorkflowIcon />}
            />
          }
          content={
            <ShowModuleContent
              isMobile={isMobile}
              isLeft={true}
              title={<div style={titleStyle}>Customized Workflow </div>}
              content='Create your workflow to be followed in your factory. Place checks and validations for critical operations.'
            />
          }
        />
      </div>
    </div>
  );
};
const QualityControlIcon = () => {
  return (
    <svg
      width='40'
      height='43'
      viewBox='0 0 40 43'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M37.2266 7.14062L21.4766 0.578125C21.1484 0.414062 20.4102 0.25 20 0.25C19.5898 0.25 18.8516 0.414062 18.5234 0.578125L2.77344 7.14062C1.29688 7.71484 0.3125 9.19141 0.3125 10.75C0.3125 27.0742 9.66406 38.3125 18.5234 41.9219C19.4258 42.332 20.5742 42.332 21.4766 41.9219C28.5312 39.0508 39.6875 28.8789 39.6875 10.75C39.6875 9.19141 38.7031 7.71484 37.2266 7.14062ZM27.3828 17.9688L19.5078 27.1562C19.1797 27.5664 18.6875 27.8125 18.1133 27.8125H18.0312C17.5391 27.8125 17.0469 27.5664 16.6367 27.2383L12.6992 23.3008C11.9609 22.5625 11.9609 21.25 12.6992 20.5117C13.4375 19.7734 14.75 19.7734 15.4883 20.5117L17.9492 22.9727L24.4297 15.3438C25.0859 14.5234 26.3984 14.4414 27.2188 15.1797C28.0391 15.8359 28.1211 17.1484 27.3828 17.9688Z'
        fill='#027AFF'
      />
    </svg>
  );
};
const QualityControl = () => {
  //eslint-disable-next-line
  const [isTab, isMobile] = useMobile();
  return (
    <div className='section-2-content section-2-sub-content'>
      <div className='pb-4'>
        <ShowModule
          isMobile={isMobile}
          isLeft={false}
          image={
            <ProductStepIcon
              alignment={'left'}
              isMobile={isMobile}
              isLeft={false}
              icon={<QualityControlIcon />}
            />
          }
          content={
            <ShowModuleContent
              isMobile={isMobile}
              isLeft={false}
              title={<div style={titleStyle}>Quality Control</div>}
              content='Capture rejections at any stage of production to ensure no defective items are delivered and analyse the data to find the cause of the defects.'
            />
          }
        />
      </div>
    </div>
  );
};
const PalletTrackingIcon = () => {
  return (
    <svg
      width='53'
      height='43'
      viewBox='0 0 53 43'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M37 13.375H42.25C43.7266 13.375 44.875 12.2266 44.875 10.75V5.5C44.875 4.02344 43.7266 2.875 42.25 2.875H37C35.5234 2.875 34.375 4.02344 34.375 5.5V10.75C34.375 12.2266 35.5234 13.375 37 13.375ZM37 26.5H47.5C48.9766 26.5 50.125 25.3516 50.125 23.875V18.625C50.125 17.1484 48.9766 16 47.5 16H37C35.5234 16 34.375 17.1484 34.375 18.625V23.875C34.375 25.3516 35.5234 26.5 37 26.5ZM18.625 26.5H29.125C30.6016 26.5 31.75 25.3516 31.75 23.875V5.5C31.75 4.02344 30.6016 2.875 29.125 2.875H18.625C17.1484 2.875 16 4.02344 16 5.5V23.875C16 25.3516 17.1484 26.5 18.625 26.5ZM51.4375 31.75H10.75V5.5C10.75 2.62891 8.37109 0.25 5.5 0.25H1.5625C0.824219 0.25 0.25 0.824219 0.25 1.5625V4.1875C0.25 4.92578 0.824219 5.5 1.5625 5.5H4.1875C4.92578 5.5 5.5 6.07422 5.5 6.8125V35.6875C5.5 36.4258 6.07422 37 6.8125 37H13.6211C13.457 37.4102 13.375 37.8203 13.375 38.3125C13.375 40.4453 15.1797 42.25 17.3125 42.25C19.4453 42.25 21.25 40.4453 21.25 38.3125C21.25 37.8203 21.168 37.4102 21.0039 37H37.2461C37.082 37.4102 37 37.8203 37 38.3125C37 40.4453 38.8047 42.25 40.9375 42.25C43.0703 42.25 44.875 40.4453 44.875 38.3125C44.875 37.8203 44.793 37.4102 44.6289 37H51.4375C52.1758 37 52.75 36.4258 52.75 35.6875V33.0625C52.75 32.3242 52.1758 31.75 51.4375 31.75Z'
        fill='#027AFF'
      />
    </svg>
  );
};
const PalletTracking = () => {
  //eslint-disable-next-line
  const [isTab, isMobile] = useMobile();
  return (
    <div className='section-2-content section-2-sub-content'>
      <div className='pb-4'>
        <ShowModule
          isMobile={isMobile}
          isLeft={true}
          image={
            <ProductStepIcon
              alignment={'right'}
              isMobile={isMobile}
              isLeft={true}
              icon={<PalletTrackingIcon />}
            />
          }
          content={
            <ShowModuleContent
              isMobile={isMobile}
              isLeft={true}
              title={<div style={titleStyle}>Pallet Tracking</div>}
              content='Every item can be traced back to the pallet it is currently located in. '
            />
          }
        />
      </div>
    </div>
  );
};
const MachineIntegrationIcon = () => {
  return (
    <svg
      width='53'
      height='38'
      viewBox='0 0 53 38'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10.75 6.125H42.25V27.125H47.5V4.8125C47.5 2.67969 45.6953 0.875 43.5625 0.875H9.4375C7.30469 0.875 5.5 2.67969 5.5 4.8125V27.125H10.75V6.125ZM51.4375 29.75H1.5625C0.824219 29.75 0.25 30.3242 0.25 31.0625V32.375C0.25 35.2461 2.62891 37.625 5.5 37.625H47.5C50.3711 37.625 52.75 35.2461 52.75 32.375V31.0625C52.75 30.3242 52.1758 29.75 51.4375 29.75ZM32.8984 21.0547C33.2266 21.3828 33.6367 21.5469 34.0469 21.5469C34.457 21.5469 34.8672 21.3828 35.1953 21.0547L39.1328 17.1172C39.7891 16.4609 39.7891 15.4766 39.1328 14.8203L35.1953 10.8828C34.5391 10.2266 33.5547 10.2266 32.8984 10.8828C32.2422 11.5391 32.2422 12.5234 32.8984 13.1797L35.6875 15.9688L32.8984 18.7578C32.2422 19.4141 32.2422 20.3984 32.8984 21.0547ZM20.1016 10.8828C19.4453 10.2266 18.4609 10.2266 17.8047 10.8828L13.8672 14.8203C13.2109 15.4766 13.2109 16.4609 13.8672 17.1172L17.8047 21.0547C18.1328 21.3828 18.543 21.5469 18.9531 21.5469C19.3633 21.5469 19.7734 21.3828 20.1016 21.0547C20.7578 20.3984 20.7578 19.4141 20.1016 18.7578L17.3125 15.9688L20.1016 13.1797C20.7578 12.5234 20.7578 11.5391 20.1016 10.8828ZM27.2383 9.81641L22.6445 22.2852C22.3164 23.1055 22.8086 24.0898 23.6289 24.418C23.793 24.5 24.0391 24.5 24.2031 24.5C24.8594 24.5 25.5156 24.0898 25.7617 23.4336L30.3555 10.9648C30.6836 10.1445 30.1914 9.16016 29.3711 8.83203C28.5508 8.50391 27.5664 8.99609 27.2383 9.81641Z'
        fill='#027AFF'
      />
    </svg>
  );
};
const MachineIntegration = () => {
  //eslint-disable-next-line
  const [isTab, isMobile] = useMobile();
  return (
    <div className='section-2-content section-2-sub-content'>
      <div className='pb-4'>
        <ShowModule
          isMobile={isMobile}
          isLeft={false}
          image={
            <ProductStepIcon
              alignment={'left'}
              isMobile={isMobile}
              isLeft={false}
              icon={<MachineIntegrationIcon />}
            />
          }
          content={
            <ShowModuleContent
              isMobile={isMobile}
              isLeft={false}
              title={<div style={titleStyle}>Machine Integration</div>}
              content='NAADI Bot provides direct Integration with CNC Machines to deliver the programs and to capture the output '
            />
          }
        />
      </div>
    </div>
  );
};
const TrackNTrace = () => {
  const history = useHistory();
  //eslint-disable-next-line
  const [isTab, isMobile] = useMobile();
  return (
    <Fragment>
      <div className='d-none d-lg-block py-32'></div>
      <div className='px-32'>
        <div className='section-2-wrp' style={{ padding: '0px' }}>
          <div className='section-2-content' style={{ paddingTop: '0px' }}>
            <div className='pb-4'>
              <ShowModule
                isMobile={isMobile}
                isLeft={true}
                valign={'center'}
                image={
                  <TrackNTraceImage
                    alignment={'right'}
                    isMobile={isMobile}
                    isLeft={true}
                  />
                }
                content={
                  <ShowModuleContent
                    isMobile={isMobile}
                    isLeft={true}
                    title={<ModulesComponent name={'Track N Trace'} />}
                    content='Real time Visibility of orders in your factory'
                  />
                }
              />
            </div>
          </div>
          <TrackMeasureRecord />
          <PaperlessProductionTracking />
          <OperatorGuidance />
          <CustomizeWorkflow />
          <QualityControl />
          <PalletTracking />
          <MachineIntegration />
        </div>
        <div className='py-64'>
          <div className='text-center'>
            <div
              className='btn btn-outline-primary cursor-pointer'
              style={{ minWidth: isMobile || isTab ? '150px' : '400px' }}
              onClick={() => {
                history.push('/website/reachus');
              }}
            >
              Book a Demo
            </div>
          </div>
          <WhatsAppChat />
          <div className='text-center ' style={{ paddingTop: '32px' }}>
            <div
              className='btn btn-ghost cursor-pointer'
              style={{ minWidth: '400px' }}
              onClick={() => {
                history.push('/website');
              }}
            >
              Home
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default TrackNTrace;
